<template>
  <div class="vx-row">

    <div class="vx-col md:w-1/3 w-full mb-1">
      <vx-card title="Rollen">
        <div class="vx-row mb-6 mt-0">
          <div class="vx-col w-full">
            <vx-input-group class="mb-0">
              <vs-input v-model="role_name" v-validate="'required|min:4'" placeholder="Key der Rolle" name="role_name"/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button color="success" @click="createRole">Erstellen</vs-button>
                </div>
              </template>
            </vx-input-group>
            <span class="text-danger text-sm">{{ errors.first('role_name') }}</span>
          </div>
        </div>

        <vs-list>
          <vs-list-header title="Rollen Liste"></vs-list-header>

          <vs-list-item v-for="role in roles" :title="role.long_name" :subtitle="role.name">
            <vs-button  color="primary" type="border"  size="small" @click="selectRole(role)">bearbeiten</vs-button>
          </vs-list-item>
        </vs-list>

      </vx-card>
    </div>

    <div class="vx-col md:w-2/3 w-full mb-1">
      <vx-card title="Berechtigungen">

        <vs-alert :active="!selectedRole">
          Keine Rolle ausgewählt
        </vs-alert>

        <div v-if="selectedRole">
          <vs-button color="success" @click="updateRole" style="position: absolute; right: 1.5rem; bottom: calc(100% - 1rem)">Speichern</vs-button>

          <div class="vx-row mb-1 sm:w-3/4">
            <!--
            <div class="vx-col sm:w-1/2 w-full mb-0">
              <vs-input class="w-full" label-placeholder="Rollen Key" name="firstname" v-model="selectedRole.name"/>
              <span class="text-danger text-sm">{{ errors.first('firstname') }}</span>
            </div>
            -->
            <div class="vx-col sm:w-1/2 w-full mb-0">
              <vs-input class="w-full" label-placeholder="Beschreibung" name="long_name"  v-model="selectedRole.long_name"/>
              <span class="text-danger text-sm">{{ errors.first('long_name') }}</span>
            </div>
          </div>

          <vs-list v-for="permissionGroup in permissionGroups">
            <vs-list-header :title="permissionGroup.name"></vs-list-header>

            <vs-list-item v-for="permission in permissionGroup.permissions" :title="permission.long_name"
                          :subtitle="permission.name">
              <vs-switch color="success" v-model="selectedRolePermissions" :vs-value="permission.name"/>
            </vs-list-item>
          </vs-list>
        </div>

      </vx-card>
    </div>
  </div>
</template>

<script>
  import ApiService from "../../../api/index";

  export default {
    name: "Permissions",
    data() {
      return {
        role_name: "",
        roles: [],
        permissionGroups: [],
        val: false,
        selectedRole: null,
        selectedRolePermissions: []
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      fetchData() {
        this.$vs.loading()

        const rolePromise = ApiService.get('roles')
        const permissionPromise = ApiService.get('permission_groups')

        Promise.all([rolePromise, permissionPromise])
          .then(values => {
            this.roles = values[0].data.result;
            this.permissionGroups = values[1].data.result;
            this.$vs.loading.close()
          });
      },
      selectRole(role) {
        this.selectedRole = role;
        this.selectedRolePermissions = this.selectedRole.permissions.map(item => item.name);
      },
      validateCreateRole() {
        if(this.role_name === "") {
          return false;
        }
      },
      createRole() {
        this.$vs.loading()

        ApiService.post('roles', {name: this.role_name}).then(response => {
          this.fetchData();
          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Rolle wurde erfolgreich angelegt',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }).catch(response  => {

          this.$vs.notify({
            title: 'Fehler',
            text: 'Die Rolle konnte nicht erstellt werden.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });

          this.$vs.loading.close()
        })
      },
      updateRole() {
        this.$vs.loading();

        const payload = {
           long_name: this.selectedRole.long_name,
           permissions: this.selectedRolePermissions
        }

        ApiService.put('roles/'+this.selectedRole.id, payload).then(response => {
          this.fetchData();
          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Rolle wurde erfolgreich beabreitet',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }).catch(response  => {

          this.$vs.notify({
            title: 'Fehler',
            text: 'Die Rolle konnte nicht bearbeitet werden.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });

          this.$vs.loading.close()
        })
      },
    }
  }
</script>
